<template>
  <header class="header-offer" :style="'background-image: /offers/' + this.item.image">
    <h1 data-aos="fade-right" data-aos-duration="1500">{{ item.name }}</h1>
    <OfferBadge data-aos="fade-right" data-aos-duration="1700" :location-type="item.locationType"/>
    <p data-aos="fade-right" data-aos-duration="2000">{{ item.location }}</p>

  </header>
  <div id="offer-reservation" :class="isHidden ? 'hide-reservation' : ''">
    <div class="cell">
      <div class="out">
        <div class="in">
          <div v-on:click="switchHide" class="close">X</div>
          <h3>Rezerwacja Wycieczki</h3>
          <h4>{{ item.name }}</h4>
          <h4>{{ item.location }}</h4>
          <p>Obecnie rezerwacje przyjmujemy telefonicznie. Zadzwoń lub napisz na WhatsApp <b>+1 829 653 0012</b> lub <b>+48 720 891 444</b>.</p>
          <div class="price-o">
            <div class="price-in">
              <div>
                <b>Cena od {{ item.price }} USD</b>
              </div>
            </div>
          </div>
          <ContactIn/>
        </div>
      </div>
    </div>
  </div>

  <section data-aos="fade-up" data-aos-duration="1500" id="offer">
    <h2>Opis wycieczki</h2>
    <div class="desc" v-html="item.description">
    </div>

    <div class="offer-info">
      <div class="add-info" v-html="item.info">
      </div>
      <div class="price-o">
        <div class="price-in">
          <div>
            <b>Cena od {{ item.price }} USD</b>
          </div>

        </div>
        <div @click="switchHide" class="reservation button">
          <p>
            Rezerwacja
          </p>
        </div>
        <div class="cb"></div>
      </div>
    </div>
  </section>
  <OfferList
      :offers="offersByLocation(item.locationType)"
  />
</template>

<script>
import ContactIn from "@/components/contact/ContactIn";
import OfferList from "@/components/offert/OfferList";
import {offer, offersByLocation} from "@/components/offert/offerData";
import OfferBadge from "@/components/offert/OfferBadge.vue";

export default {
  name: "OfferRoute",
  components: {OfferBadge, OfferList, ContactIn},
  data() {
    return {
      item: offer(this.$route.params.id.replace("-", " ")),
      isHidden: true,
    };
  },
  methods: {
    offersByLocation,
    switchHide() {
      this.isHidden = !this.isHidden
    },
  }
}
</script>
